import { gql } from '@apollo/client'

export const WATER_COUNT_QUERY = gql`
	query WaterCount($data: SelectManagementInput!) {
		selectProjectCountBySiteIdAndMonth(data: $data) {
			count
			site {
				name
				id
			}
			user {
				id
				name
			}
		}
	}
`

export const WATER_MONTH_QUERY = gql`
	query WaterMonth($data: SelectManagementInput!) {
		selectProjectByMonth(data: $data) {
			id
			regDt
			analysisProcess
			measurements {
				item {
					id
					name
				}
				sample {
					id
					name
				}
				ppm
				itemKind
				dilution
			}
		}
	}
`

export const PROJECTS_QUERY = gql`
	query Projects($data: SelectProjectInput!) {
		selectProjectListByRegDt(data: $data) {
			id
			regDt
			note
			analysisProcess
			measurements {
				id
				ppm
				item {
					id
					name
				}
				sample {
					id
					name
				}
				dilution
			}
			user {
				id
				name
			}

			files {
				id
				item {
					id
					name
				}
				filePath
			}
		}
	}
`

export const PROJECT_ITEM_QUERY = gql`
	query ProjectItem {
		selectItemList(data: { type: ITEM1 }) {
			id
			name
			ord
			time
		}

		selectSampleList {
			id
			name
			analysisProcess
			type
			ord
		}
	}
`

export const UPDATE_PROJECT_FILE_QUERY = gql`
	mutation UpdateProjectFile($data: UpdateProjectFileInput!) {
		updateProjectFile(data: $data) {
			data
		}
	}
`

export const DELETE_PROJECT_FILE_QUERY = gql`
	mutation DeleteProjectFile($id: Float!) {
		deleteProjectFile(id: $id) {
			data
		}
	}
`
