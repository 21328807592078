import { useMutation, useQuery } from '@apollo/client'
import ImageModal from 'components/modal/ImageModal'
import OperationDayTable5 from 'components/operation/OperationDayTable5'
import OperationDayTable6 from 'components/operation/OperationDayTable6'
import View from 'components/templete/view'
import SiteAndDayUtils from 'components/utils/SiteAndDayUtils'
import WaterDayDetailTable from 'components/water/WaterDayDetailTable'
import WaterDayPrintTable from 'components/water/WaterDayPrintTable'
import WaterDayTable from 'components/water/WaterDayTable'
import WaterGraphTable from 'components/water/WaterGraphTable'
import { useImageUpload } from 'hooks/useImageUpload'
import useSearch, { getSearchInitData } from 'hooks/useSearch'
import moment from 'moment'
import Print from 'pages/Print'
import { PROJECTS_QUERY, PROJECT_ITEM_QUERY, UPDATE_PROJECT_FILE_QUERY } from 'queries/waterQuery'
import React, { useCallback, useRef, useState } from 'react'
import { SelectorValueType } from 'types/common'
import {
    ProjectItem,
    Projects,
    ProjectsVariables,
    Projects_selectProjectListByRegDt,
    SearchKindEnum,
    UpdateProjectFile,
    UpdateProjectFileVariables
} from 'types/graphql'
import * as XLSX from "xlsx"

function WaterDay() {
    const printRef = useRef<HTMLDivElement>(null)
    const [path, setPath] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [project, setProject] = useState<Projects_selectProjectListByRegDt>()
    const {onChangeSiteId, onChangeRegDt, searchData} = useSearch(
        '/water/day',
        getSearchInitData(SearchKindEnum.GROUP_NAME),
    )
    const {data, refetch} = useQuery<Projects, ProjectsVariables>(PROJECTS_QUERY, {
        variables: {data: {siteId: Number(searchData.siteId), regDt: moment(searchData.regDt).format('YYYY-MM-DD')}},
        onCompleted: ({selectProjectListByRegDt}) => {
            if (selectProjectListByRegDt && selectProjectListByRegDt.length > 0) {
                setProject(selectProjectListByRegDt[0])
            }
        },
    })

    const [projectFileSave] = useMutation<UpdateProjectFile, UpdateProjectFileVariables>(UPDATE_PROJECT_FILE_QUERY, {
        update: cache => cache.reset(),
    })

    const {imageSave} = useImageUpload({
        onCompleted: async ({fileUpload}) => {
            if (fileUpload && project) {
                await projectFileSave({
                    variables: {
                        data: {
                            projectId: project.id,
                            itemId: Number(fileUpload.refId),
                            filePath: fileUpload.url,
                        },
                    },
                })
                setPath(fileUpload.url)
                const {data} = await refetch({data: {regDt: searchData.regDt, siteId: searchData.siteId}})
                if (data && data.selectProjectListByRegDt) {
                    const findProject = data.selectProjectListByRegDt.find(p => p.id === project.id)
                    setProject(findProject)
                }
            }
        },
    })

    const itemResult = useQuery<ProjectItem>(PROJECT_ITEM_QUERY)

    const _onChangeSelector = useCallback(
        async (value: SelectorValueType) => {
            setProject(undefined)
            onChangeSiteId(Number(value))
            const result = await refetch({data: {regDt: searchData.regDt, siteId: Number(value)}})
            if (
                result &&
                result.data &&
                result.data.selectProjectListByRegDt &&
                result.data.selectProjectListByRegDt.length > 0
            ) {
                setProject(result.data.selectProjectListByRegDt[0])
            }
        },
        [searchData],
    )

    const _onChangeRegDt = useCallback(
        async (date: Date) => {
            setProject(undefined)
            onChangeRegDt(date)
            const result = await refetch({
                data: {
                    regDt: moment(date).format('YYYY-MM-DD'),
                    siteId: searchData.siteId,
                },
            })
            if (
                result &&
                result.data &&
                result.data.selectProjectListByRegDt &&
                result.data.selectProjectListByRegDt.length > 0
            ) {
                setProject(result.data.selectProjectListByRegDt[0])
            }
        },
        [searchData],
    )

    const onClickProject = useCallback(
        (id: number) => {
            const findProject = data?.selectProjectListByRegDt.find(p => p.id === id)
            setProject(findProject)
        },
        [data],
    )

    const onOpenModal = useCallback((path: string) => {
        setPath(path)
        setIsOpen(true)
    }, [])

    const onCloseModal = useCallback(() => {
        setIsOpen(false)
    }, [])

    const onChangeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.item(0)
        if (file) imageSave({file, refId: Number(e.target.name)})
        e.target.value = ''
    }, [])

    const getSamples = useCallback(() => {
        if (!project) return []
        return itemResult.data?.selectSampleList.filter(
            sample => sample.analysisProcess === project?.analysisProcess,
        )
    }, [itemResult, project])

    const onDownloadCSV = useCallback(() => {
        const headers = getHeaders()
        const csvData = getData()

        if (!headers || !csvData) return

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([
            headers,
            ...csvData
        ]);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, moment().format("YYYYMMDDHHmmss") + "-month.xlsx");
    }, [project, itemResult]);

    const getHeaders = useCallback(() => {
        const samples = getSamples()
        const items = itemResult.data?.selectItemList

        if (!samples || !items) return
        const topHeaders = ["측정항목"]

        for (const sample of samples) {
            topHeaders.push(sample.name)
        }

        return topHeaders
    }, [itemResult])

    const getData = useCallback(() => {
        const samples = getSamples()
        const items = itemResult.data?.selectItemList
        const result: string[][] = []

        if (!samples || !items) return

        for (const item of items) {
            const initData = [item.name]
            for (const sample of samples) {
                const findProject = project?.measurements.find(
                    m => m.item.id === item.id && m.sample.name === sample.name,
                )

                const value = findProject
                    ? Number(findProject.ppm) === -1
                        ? '초과'
                        : Math.floor(findProject.ppm * findProject.dilution * 10) / 10
                    : '-'
                initData.push(String(value))
            }
            result.push(initData)
        }

        return result
    }, [itemResult, project])


    return (
        <>
            {isOpen && <ImageModal path={path} onClose={onCloseModal}/>}
            <View
                title="일일 수질 분석 결과"
                tables={[
                    <WaterDayTable
                        key="WaterDayTable"
                        projects={data?.selectProjectListByRegDt}
                        onClick={onClickProject}
                        selectProject={project}
                    />,
                    <WaterDayDetailTable
                        key="WaterDayDetailTable"
                        project={project}
                        items={itemResult.data?.selectItemList}
                        samples={getSamples()}
                        onDownloadCSV={onDownloadCSV}
                    />,
                    <OperationDayTable5
                        key="OperationDayTable5"
                        files={project?.files}
                        items={itemResult.data?.selectItemList}
                        onOpenModal={onOpenModal}
                        onChangeFile={onChangeFile}
                    />,
                    <WaterGraphTable
                        key="WaterGraphTable"
                        project={project}
                        items={itemResult.data?.selectItemList}
                        samples={getSamples()}
                    />,
                    <Print key="printDiv" printRef={printRef}>
                        <WaterDayPrintTable
                            project={project}
                            items={itemResult.data?.selectItemList}
                            samples={getSamples()}
                        />
                        <OperationDayTable6
                            key="OperationDayTable6"
                            files={project?.files}
                            items={itemResult.data?.selectItemList}
                            onOpenModal={onOpenModal}
                            onChangeFile={onChangeFile}
                        />
                    </Print>
                ]}
                utils={
                    <SiteAndDayUtils
                        onChangeSelector={_onChangeSelector}
                        onChangeRegDt={_onChangeRegDt}
                        searchData={searchData}
                        printRef={printRef}
                    />
                }
            />
        </>
    )
}

export default WaterDay
