import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import moment from 'moment'
import React from 'react'
import {
	AnalysisProcessEnum,
	Items_selectItemList,
	Samples_selectSampleList,
	WaterMonth_selectProjectByMonth,
} from 'types/graphql'

type Props = {
	items?: Items_selectItemList[]
	samples?: Samples_selectSampleList[]
	projects: WaterMonth_selectProjectByMonth[][]
}
const ColDefaultStyle = {
	flexDirection: 'column',
	padding: '0',
	border: '0',
} as React.CSSProperties

const WaterMonthTable: React.FC<Props> = ({ items, samples, projects }) => {

	return (
		<Table isScroll>
			<Table.Row bgColor="#E6E8EE" style={{ width: '2200px' }}>
				<Table.Col width="100px">
					<span css={textStyle}>측정일</span>
				</Table.Col>
				{samples
					?.filter(sample => {
						const analysisProcessObject = {'A2O': 0, MBR: 0}
						for (const projectList of projects) {
							for (const project of projectList) {
								analysisProcessObject[project.analysisProcess]++
							}
						}
						if(analysisProcessObject['A2O'] === 0) {
							return sample.analysisProcess === AnalysisProcessEnum.MBR
						}
						return sample.analysisProcess === AnalysisProcessEnum.A2O
					})
					?.map(sample => {
						return (
							<Table.Col width="350px" style={ColDefaultStyle} key={sample.id} bgColor="#E6E8EE">
								<Table.Row>
									<Table.Col bgColor="#E6E8EE" style={{ width: '350px', borderRight: '0' }}>
										<span css={textStyle}>{sample.name}</span>
									</Table.Col>
								</Table.Row>
								<Table.Row style={{ borderBottom: '0px', width: "100%" }}>
									{items?.map((item, index) => {
										const style = index === 0 ? {} : index === items.length - 1 ? { borderRight: 0 } : {}
										return (
											<Table.Col style={style} key={item.id}>
												<span css={textStyle}>{item.name}</span>
											</Table.Col>
										)
									})}
								</Table.Row>
							</Table.Col>
						)
					})}
			</Table.Row>
			{projects?.map(projectList => {
				return (
					<Table.Row bgColor="#E6E8EE" key={projectList[0].id} style={{ width: '2200px' }}>
						<Table.Col width="100px">
							<span css={textStyle}>{moment(projectList[0].regDt).format('MM월 DD일')}</span>
						</Table.Col>
						<Table.Col padding="0" style={{ border: '0', display: 'flex', flexDirection: 'column' }}>
							{projectList.map((project, idx) => {
								const style = idx === 0 ? { borderBottom: 0, borderTop: 0 } : {}
								return (
									<Table.Row key={project.id + 'data'} style={style}>
										{samples
											?.filter(sample => project.analysisProcess === sample.analysisProcess)
											.map(sample => (
												<Table.Col width="350px" style={ColDefaultStyle} key={sample.id + 'data'}>
													<Table.Row style={{ border: '0', width: '100%' }}>
														{items?.map((item, index) => {
															const style =
																index === 0
																	? {}
																	: index === items.length - 1
																	? {
																			borderRight: 0,
																			borderBottom: 0,
																	  }
																	: {}
															const findData = project?.measurements.find(
																measurement =>
																	measurement.item.id === item.id && measurement.sample.name === sample.name,
															)
															return (
																<Table.Col style={style} key={item.id + 'data'}>
																	<span css={textStyle}>
																		{findData
																			? Number(findData.ppm) === -1
																				? '초과'
																				: Math.floor(findData.ppm * findData.dilution * 10) / 10
																			: '-'}
																	</span>
																</Table.Col>
															)
														})}
													</Table.Row>
												</Table.Col>
											))}
									</Table.Row>
								)
							})}
						</Table.Col>
					</Table.Row>
				)
			})}
		</Table>
	)
}
export default WaterMonthTable
